<template>
  <div class="clothes content-index">
    <div style="padding: 17px 20px;">
      <el-page-header @back="goBack" title="上一步" content="我要取衣"/>
    </div>
    <div class="content" >
      <div class="imgView">
        <img src="../../../../src/assets/img/取衣.png" alt="">
      </div>
      <div class="content-input">
        <el-input v-model.trim="keyword"
                  maxlength="30"
                  @keyup.enter.native="handleQuery"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="请输入手机号/订单号/衣物号">
        </el-input>
        <el-button :loading="infoLoading" :class="{'btn-search':keyword !== ''}" :disabled="keyword === ''" @click="handleQuery">查询</el-button>
      </div>
      <div>
           <span class="base"><span class="num">{{ orderCount || 0 }}</span>个订单,&nbsp;<span
               class="num">{{ clothesNum || 0 }}</span>件衣物待取走</span>
      </div>
    </div>
  </div>
</template>

<script>
import {myTakeClothes, takeClothes} from '@/api/clothes/order'

export default {
  data() {
    return {
      keyword: '',
      clothesNum: '',
      orderCount: '',
      dataList: [],
      infoLoading: false
    }
  },
  created() {
    this.myTakeClothes();
  },
  methods: {
    // 处理查询
    handleQuery() {
      let _this = this;
      if(this.infoLoading){
        return
      }
      if (_this.keyword == '') {
        this.$message.error('请先输入手机号/订单号/衣物号');
        return
      }
      _this.infoLoading = true
      const params = new URLSearchParams()
      params.append('phone', this.keyword)
      takeClothes(params).then(response => {
        _this.infoLoading = false
        _this.dataList = response.data.data.orderList;
        if (_this.dataList.length > 0) {
          this.$router.push({
            path: 'takeClothes',
            query: {keyword: this.keyword}
          })
        } else {
          this.$message.error('查询没有可取的衣物');
        }
      })
    },
    myTakeClothes() {
      myTakeClothes().then((res) => {
        this.clothesNum = res.data.data.clothesNum;
        this.orderCount = res.data.data.orderCount;
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>

.clothes {
   overflow: auto;
  .content {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    

    .imgView {
      text-align: center;

      > img {
        width: 282px;
        height: 86px;
      }
    }

    .content-input {
      display: flex;
      justify-content: center;
      padding: 20px 0;

      > button {

        background: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;

        width: 64px;
        height: 44px;
        padding: 0;
        line-height: 44px;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #fff;
        background: #EEE;
        border-radius: 0 5px 5px 0;
      }

      > button.btn-search {
        background: #3370ff;
      }
    }

    .base {
      font-size: 18px;
    }

    .num {
      font-size: 23px;
      font-weight: 500;
      color: red;
    }
  }
}

/deep/ .el-input {
  width: auto;
}

/deep/ .el-input__inner {
  width: 285px;
  height: 45px;
  font-size: 15px;
  border: none;
  background: #f8f8f8;
  border-radius: 4px 0 0 4px;

}

</style>
